import React from "react"
import PropTypes from "prop-types"

import "./layout.css"

const Layout = ({ children }) => {
  return (
    <>
      <div className="container">
        <header className="pure-g">
        <div className="pure-u-5-5 pure-u-md-1-5">&nbsp;</div>
          <div className="pure-u-5-5 pure-u-md-4-5">
            <h1>
              <a className="site-title" href="/">
                Icons16X16.com
              </a>
            </h1>
          </div>
        </header>
        <main>{children}</main>

        <footer>© {new Date().getFullYear()}, JoeApple</footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
