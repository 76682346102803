import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import "./SiteNav.css"

const SiteNav = () => {
  const data = useStaticQuery(graphql`
    query {
      allSitePage(
        filter: {
          componentChunkName: {
            eq: "component---src-templates-dsp-all-images-in-dir-js"
          }
          internalComponentName: { ne: "ComponentImages" }
        }
      ) {
        edges {
          node {
            id
            path
            context {
              moreInformation {
                title
                navLink
              }
            }
          }
        }
        distinct(field: path)
      }
    }
  `)
  return (
    <>
      <div className="site-nav">
        Webdesign Icons
        {data.allSitePage.edges.map((item, key) => {
          return (
            <span className="site-nav-link" key={key}>
              <Link
                className="link"
                to={item.node.path}
                activeStyle={{ backgroundColor: "#f5f5f5" }}
              >
                {item.node.context.moreInformation[0].navLink}
              </Link>
            </span>
          )
        })}
        <span>
           | <Link to="/About">About</Link>
        </span>
      </div>
    </>
  )
}
export default SiteNav
